import { useEffect, useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { authenticationApi } from '../services/authenticationApi';
import { useUserDetails } from '../../user/hooks/useUserDetails';
import { useAuthenticationStore } from '../store/authenticationStore';
import { useUserStore } from '../../user/store/userStore';
import { usePersonStore } from '../../person/store/personStore';
import { useShareableLinkStore } from '../../public/shareable-link-media-upload/store/shareableLinkStore';
import { isTokenValid, getTokenRemainingTime } from '../../../utils/jwtUtils';

export const useAuthentication = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { refetchUser, isLoading: isLoadingUser } = useUserDetails();
    
    // Get the Zustand state updaters
    const setAuthenticated = useAuthenticationStore(state => state.login);
    const setUnauthenticated = useAuthenticationStore(state => state.logout);
    const isAuthenticated = useAuthenticationStore(state => state.isAuthenticated);
    const clearUser = useUserStore(state => state.clearUser);
    const clearPersons = usePersonStore(state => state.clearPersons);
    const clearShareableToken = useShareableLinkStore(state => state.clearShareableToken);

    const queryClient = useQueryClient();

    // Check token validity on authenticated routes and handle cross-tab authentication
    useEffect(() => {
        // For new tab or page reload: if token is valid but state is not authenticated,
        // update the state without requiring login
        if (!isAuthenticated && isTokenValid()) {
            if (typeof setAuthenticated === 'function') {
                setAuthenticated();
                // Fetch user data since we're restoring state
                refetchUser();
            }
            return;
        }
        
        // Skip for public/auth routes
        if (
            location.pathname.startsWith('/auth/') || 
            location.pathname.startsWith('/public/')
        ) {
            return;
        }
        
        // If on protected route but token is invalid, logout
        if (!isTokenValid()) {
            resetAllState();
            navigate('/auth/login', { replace: true });
        }
    }, [location.pathname, isAuthenticated, setAuthenticated, refetchUser]);
    
    // Setup token expiration monitoring
    useEffect(() => {
        if (!isAuthenticated) return;
        
        const remainingTime = getTokenRemainingTime();
        
        // If token is going to expire in the next 5 minutes
        if (remainingTime > 0 && remainingTime < 300) {
            
            // Set up a timer to check near expiration
            const timer = setTimeout(() => {
                if (!isTokenValid()) {
                    resetAllState();
                    navigate('/auth/login', { replace: true });
                }
            }, (remainingTime - 60) * 1000); // Check 1 minute before expiration
            
            return () => clearTimeout(timer);
        }
        
        // If already expired, logout immediately
        if (remainingTime <= 0) {
            resetAllState();
            navigate('/auth/login', { replace: true });
        }
    }, [isAuthenticated]);

    // Reset all application state
    const resetAllState = useCallback(() => {
        
        // Save current path for redirecting back after login (if not on login page)
        if (!location.pathname.includes('/auth/login')) {
            sessionStorage.setItem('redirectAfterLogin', location.pathname + location.search);
        }
        
        // Clear localStorage auth tokens first
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('user_id');
        
        // Preserve shareable token for shareable link pages
        if (!location.pathname.includes('/public/')) {
            localStorage.removeItem('shareable_token');
        }
        
        // Clear persisted Zustand state from localStorage
        Object.keys(localStorage).forEach(key => {
            if (key.endsWith('-storage') && !key.includes('shareable')) {
                localStorage.removeItem(key);
            }
        });
        
        // Then update Zustand stores if functions exist
        if (typeof setUnauthenticated === 'function') {
            setUnauthenticated();
        } else {
            useAuthenticationStore.setState({ isAuthenticated: false });
        }
        
        if (typeof clearUser === 'function') {
            clearUser();
        } else {
            useUserStore.setState({ user: null, userFeedActivites: [] });
        }
        
        if (typeof clearPersons === 'function') {
            clearPersons();
        } else {
            usePersonStore.setState({ people: [] });
        }
        
        if (typeof clearShareableToken === 'function' && !location.pathname.includes('/public/')) {
            clearShareableToken();
        }
        
        // Clear React Query cache
        queryClient.clear();
        
        // Force event handling to work
        document.body.style.pointerEvents = 'auto';
        document.documentElement.style.pointerEvents = 'auto';
    }, [setUnauthenticated, clearUser, clearPersons, clearShareableToken, queryClient, location]);

    // Login mutation with improved handling
    const loginMutation = useMutation({
        mutationFn: async (credentials) => {
            // First, ensure all state is reset before attempting login
            resetAllState();
            
            // Small delay to ensure everything is cleaned up
            await new Promise(resolve => setTimeout(resolve, 50));
            
            try {
                // Then attempt login
                return await authenticationApi.login(credentials);
            } catch (error) {
                console.error('Login API error:', error);
                throw error;
            }
        },
        onSuccess: (response) => {            
            // Handle failed login response
            if (!response.success || response.status_code === 401 || !response.data) {
                console.error('Login failed:', response.message || 'Unknown reason');
                return;
            }
            
            // Extract token and user ID safely
            const jwt_token = response.data.jwt_token;
            const user_id = response.data.user_id;
            
            if (!jwt_token || !user_id) {
                console.error('Missing jwt_token or user_id in response:', response);
                return;
            }
            
            // Set auth data in localStorage
            localStorage.setItem('jwt_token', jwt_token);
            localStorage.setItem('user_id', user_id);
            
            // Update auth state
            if (typeof setAuthenticated === 'function') {
                setAuthenticated();
            } else {
                useAuthenticationStore.setState({ isAuthenticated: true });
            }
            
            // Fix clicking issue
            document.body.style.pointerEvents = 'auto';
            document.documentElement.style.overflow = 'auto';
            
            // Fetch user details
            refetchUser();
            
            // Redirect to saved path or home
            const redirectPath = sessionStorage.getItem('redirectAfterLogin') || '/home';
            sessionStorage.removeItem('redirectAfterLogin');
            navigate(redirectPath);
        },
        onError: (error) => {
            console.error('Login error:', error);
            resetAllState();
        }
    });

    // Improved logout handler
    const handleLogout = useCallback(async () => {        
        // Reset all state first
        resetAllState();
        
        // Small delay to ensure state is cleared before redirect
        setTimeout(() => {
            navigate('/auth/login');
        }, 100);
    }, [resetAllState, navigate]);

    // Check if token is valid
    const checkTokenValidity = useCallback(() => {
        return isTokenValid();
    }, []);

    return {
        login: loginMutation.mutate,
        logout: handleLogout,
        checkTokenValidity,
        resetAllState,
        isLoading: {
            login: loginMutation.isPending || isLoadingUser,
            logout: false
        },
        error: {
            login: loginMutation.error,
            logout: null
        }
    };
};