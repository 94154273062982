import { useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useAuthenticationStore } from '../features/authentication/store/authenticationStore';
import NavigationLayout from '../features/navigation/components/navigation-layout/NavigationLayout';
import HomePage from '../features/home/HomePage';
import LandingPage from '../features/landing/LandingPage';
import CollectionViewPage from '../features/collection/CollectionViewPage';
import LoginComponent from '../features/landing/components/login/LoginComponent';
import SignupComponent from '../features/landing/components/signup/SignupComponent';
import ResetPasswordComponent from '../features/landing/components/reset-password/ResetPasswordComponent';
import { ShareableLinkMediaUploadPage } from '../features/public/shareable-link-media-upload/ShareableLinkMediaUploadPage';
import EnhancedAuthGuard from '../features/authentication/components/AuthGuard';
import { isTokenValid } from '../utils/jwtUtils';

const PublicGuard = ({ children }) => {
    const isAuthenticated = useAuthenticationStore(state => state.isAuthenticated);
    const setAuthenticated = useAuthenticationStore(state => state.login);
    
    // If token is valid but state says not authenticated (new tab scenario)
    // update the auth state and redirect to home
    useEffect(() => {
        if (isTokenValid() && !isAuthenticated && typeof setAuthenticated === 'function') {
            console.log('Valid token found on public route, restoring auth state');
            setAuthenticated();
        }
    }, [isAuthenticated, setAuthenticated]);
    
    // Only check token validity, regardless of auth state
    return isTokenValid() ? <Navigate to="/home" replace /> : children;
};

const routes = [
    {
        path: '/',
        element: <Navigate to="/auth/login" replace />
    },
    {
        path: 'public/upload/:token',
        element: <ShareableLinkMediaUploadPage />
    },
    {
        path: 'auth',
        element: (
            <PublicGuard>
                <LandingPage />
            </PublicGuard>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="login" replace />
            },
            {
                path: 'login',
                element: <LoginComponent />
            },
            {
                path: 'signup',
                element: <SignupComponent />
            },
            {
                path: 'reset-password',
                element: <ResetPasswordComponent />
            }
        ]
    },
    {
        path: '/',
        element: (
            <EnhancedAuthGuard>
                <NavigationLayout />
            </EnhancedAuthGuard>
        ),
        children: [
            {
                path: '*',
                element: <HomePage />
            },
            {
                path: 'collection/:collectionId',
                element: <CollectionViewPage />
            }
        ]
    }
];

const router = createBrowserRouter(routes);

export const AppRouter = () => <RouterProvider router={router} />;