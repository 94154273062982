import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticationStore } from '../store/authenticationStore';
import { isTokenValid } from '../../../utils/jwtUtils';

const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useAuthenticationStore(state => state.isAuthenticated);
    const setAuthenticated = useAuthenticationStore(state => state.login);
    const setUnauthenticated = useAuthenticationStore(state => state.logout);
    
    useEffect(() => {
        // Skip validation for public routes
        if (location.pathname.startsWith('/auth/') || 
            location.pathname.startsWith('/public/')) {
            return;
        }
        
        // Check if token exists and is valid
        if (isTokenValid()) {
            // If token is valid but state says not authenticated (e.g., new tab)
            // update the authentication state
            if (!isAuthenticated && typeof setAuthenticated === 'function') {
                console.log('Valid token found, restoring authentication state');
                setAuthenticated();
            }
        } else {
            // Token is invalid or expired
            console.log('JWT token invalid or expired, logging out');
            
            // Remember current location for redirect after login
            sessionStorage.setItem('redirectAfterLogin', location.pathname + location.search);
            
            // Clear authentication state
            if (typeof setUnauthenticated === 'function') {
                setUnauthenticated();
            }
            
            // Clear authentication data from localStorage
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('user_id');
            
            // Clear Zustand storage that's not related to shareable links
            Object.keys(localStorage).forEach(key => {
                if (key.endsWith('-storage') && !key.includes('shareable')) {
                    localStorage.removeItem(key);
                }
            });
            
            // Navigate to login page
            navigate('/auth/login', { replace: true });
        }
    }, [location, isAuthenticated, setAuthenticated, setUnauthenticated, navigate]);
    
    // Allow access if token is valid, regardless of application state
    return isTokenValid() ? children : <Navigate to="/auth/login" replace />;
};

export default AuthGuard;