import axios from 'axios';
import { isTokenValid } from '../../utils/jwtUtils';

const getApiUrl = () => {
  console.log('Environment:', process.env.NODE_ENV);
  
  // For local development, use the deployment repo's .env setting
  if (process.env.NODE_ENV !== 'production') {
    console.log('Using local API base URL');
    return 'http://localhost';
  }
  
  // For production - explicitly use /api as the base URL
  return '/api';
};

export const API_URL = getApiUrl();
console.log('API_URL initialized as:', API_URL);

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

apiClient.interceptors.request.use(
  (config) => {    
    // Skip auth headers for login requests
    if (config.url && (config.url.includes('/login') || config.url.endsWith('/login'))) {
      // For login requests, don't send authorization token
      delete config.headers['Authorization'];
      config.withCredentials = false;
      return config;
    }
    
    // First check for shareable token
    const shareableToken = localStorage.getItem('shareable_token');
    
    if (shareableToken) {
      config.headers.Authorization = `Bearer ${shareableToken}`;
      // For public endpoints, we don't want to send credentials
      config.withCredentials = false;
      return config;
    }

    // If no shareable token, check for JWT token
    const jwt_token = localStorage.getItem('jwt_token');
    if (jwt_token) {
      // Verify the token is still valid before sending
      if (isTokenValid()) {
        config.headers.Authorization = `Bearer ${jwt_token}`;
        // For authenticated endpoints, we do want to send credentials
        config.withCredentials = true;
        
        // If we have a valid token, ensure we're marking as authenticated in local storage
        // This helps with cross-tab authentication persistence
        try {
          const authStorage = localStorage.getItem('authentication-storage');
          if (authStorage) {
            const authData = JSON.parse(authStorage);
            if (!authData.state.isAuthenticated) {
              // Update the stored state to reflect authentication
              authData.state.isAuthenticated = true;
              localStorage.setItem('authentication-storage', JSON.stringify(authData));
            }
          }
        } catch (e) {
          console.error('Error updating auth storage:', e);
        }
      } else {
        // Token is invalid or expired, don't include it and trigger logout
        // But only if we're not already on the login page
        const isLoginPage = window.location.pathname.includes('/login') || 
                           window.location.pathname.includes('/auth/');
        
        if (!isLoginPage) {
          console.error('JWT token invalid or expired during request');
          
          // Handle invalid token (will be caught by response interceptor)
          setTimeout(() => {
            // Clear auth data
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('user_id');
            
            // Only clear Zustand storage that's not related to shareable links
            Object.keys(localStorage).forEach(key => {
              if (key.endsWith('-storage') && !key.includes('shareable')) {
                localStorage.removeItem(key);
              }
            });
            
            window.location.href = '/auth/login';
          }, 100);
        }
        
        delete config.headers['Authorization'];
      }
    } else {
      // No token found, remove the Authorization header if it exists
      delete config.headers['Authorization'];
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(`API Error: ${error.message} for ${error.config?.url}`);
    
    // Handle 401 Unauthorized errors
    if (error.response && error.response.status === 401) {
      // Don't logout if already on login page to avoid redirect loops
      const isLoginPage = window.location.pathname.includes('/login') || 
                          window.location.pathname.includes('/auth/');
      
      // Also don't logout for shareable link pages - they handle their own auth
      const isShareableLinkPage = window.location.pathname.includes('/link/') ||
                                 window.location.pathname.includes('/public/');
      
      if (!isLoginPage && !isShareableLinkPage) {
        console.error('Session expired or invalid. Redirecting to login.');
        
        // Save current path for redirecting back after login
        const currentPath = window.location.pathname + window.location.search;
        if (currentPath !== '/auth/login') {
          sessionStorage.setItem('redirectAfterLogin', currentPath);
        }
        
        // Backup shareable token if it exists
        const shareableToken = localStorage.getItem('shareable_token');
        
        // Clear auth data
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('user_id');
        
        // Don't clear shareable token on 401s for shareable link related URLs
        if (error.config?.url && !error.config.url.includes('shareable-link') && 
            !error.config.url.includes('public')) {
          console.log('Removing shareable token due to non-shareable API 401');
          localStorage.removeItem('shareable_token');
        } else if (shareableToken) {
          console.log('Preserving shareable token despite 401');
        }
        
        // Only clear Zustand storage that's not related to shareable links
        Object.keys(localStorage).forEach(key => {
          if (key.endsWith('-storage') && !key.includes('shareable')) {
            localStorage.removeItem(key);
          }
        });
        
        // Delay redirect to ensure state is cleaned up
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 100);
      }
    }
    return Promise.reject(error);
  }
);