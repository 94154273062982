export const decodeToken = (token) => {
    try {
      if (!token) return null;
      
      // Split the token and get the payload (middle part)
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      
      // Decode the base64 payload
      const jsonPayload = atob(base64);
      
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };
  
  export const isTokenExpired = (decodedToken) => {
    if (!decodedToken || !decodedToken.exp) return true;
    
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decodedToken.exp < currentTime;
  };
 
  export const isTokenValid = () => {
    try {
      const token = localStorage.getItem('jwt_token');
      if (!token) return false;
      
      const decodedToken = decodeToken(token);
      if (!decodedToken) return false;
      
      return !isTokenExpired(decodedToken);
    } catch (error) {
      console.error('Error validating token:', error);
      return false;
    }
  };
  
  export const getTokenRemainingTime = () => {
    try {
      const token = localStorage.getItem('jwt_token');
      if (!token) return 0;
      
      const decodedToken = decodeToken(token);
      if (!decodedToken || !decodedToken.exp) return 0;
      
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = decodedToken.exp - currentTime;
      
      return remainingTime > 0 ? remainingTime : 0;
    } catch (error) {
      console.error('Error calculating token lifetime:', error);
      return 0;
    }
  };